/*
 * Copyright © Ergonode Sp. z o.o. All rights reserved.
 * See LICENSE for license details.
 */
<template>
    <main class="app-main">
        <slot />
    </main>
</template>

<script>
export default {
    name: 'AppMain',
};
</script>

<style lang="scss" scoped>
    .app-main {
        width: 100%;
        height: 100vh;
        padding: 48px 0 0;
        box-sizing: border-box;
        background: 0 0;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(0, #f3f6ff), color-stop(51%, #ecdede), to(#e6ecfc)) !important;
    background-image: linear-gradient(to right, #f3f6ff 0, #ecdede 51%, #e6ecfc 100%) !important;
    }
</style>
